import { useUserStore } from '~/stores/useUserStore';

export default defineNuxtRouteMiddleware(() => {
  const userStore = useUserStore();
  if (process.client) {
    if (userStore.isLogged === false) {
      return createError(404, 'Unauthorized');
    }
  }
});
